


		 function initMap() {

		        // Create a new StyledMapType object, passing it an array of styles,
		        // and the name to be displayed on the map type control.
		        var styledMapType = new google.maps.StyledMapType(
					[
					  {
						"featureType": "water",
						"elementType": "geometry.fill",
						"stylers": [
						  {
							"color": "#006341"
						  }
						]
					  }
					],
		            {name: 'Styled Map'});

		        // Create a map object, and include the MapTypeId to add
		        // to the map type control.
				
		        var map = new google.maps.Map(document.getElementById('map'), {
		          center: {lat: 41.096899, lng: -74.148025},
		          zoom: 2,
				  scrollwheel: false,
				   icon: 'assets/images/map-icon.png',
		          mapTypeControlOptions: {
		            mapTypeIds: ['roadmap', 'satellite', 'hybrid', 'terrain',
		                    'styled_map']
		          }
				  
		        });
		    // 
			var myLatlng = new google.maps.LatLng(41.096899, -74.148025);		
			var marker = new google.maps.Marker({
				position: myLatlng,
				icon: "assets/images/home01/map-icon.png",
				title:"149 Ramview Ave, Ramsey, NJ-US"
			});	
			marker.setMap(map);
			//	
			var myLatlng = new google.maps.LatLng(-11.0222484, -68.7581076);		
			var marker = new google.maps.Marker({
				position: myLatlng,
				icon: "assets/images/home01/map-icon.png",
				title:"Pando, Bolivia"
			});	
			marker.setMap(map);
			//	
			var myLatlng = new google.maps.LatLng(-12.6035667, -69.1922794);		
			var marker = new google.maps.Marker({
				position: myLatlng,
				icon: "assets/images/home01/map-icon.png",
				title:"Puerto Maldonado, Perú"
			});	
			marker.setMap(map);		
			//	
			var myLatlng = new google.maps.LatLng(-16.4973391, -68.1372745);		
			var marker = new google.maps.Marker({
				position: myLatlng,
				icon: "assets/images/home01/map-icon.png",
				title:"La Paz, Bolivia"
			});	
			marker.setMap(map);	
			//	
			var myLatlng = new google.maps.LatLng(-17.7575932, -63.1517165);		
			var marker = new google.maps.Marker({
				position: myLatlng,
				icon: "assets/images/home01/map-icon.png",
				title:"Santa Cruz, Bolivia"
			});	
			marker.setMap(map);	
		        //Associate the styled map with the MapTypeId and set it to display.
		        map.mapTypes.set('styled_map', styledMapType);
		        map.setMapTypeId('styled_map');
		}

